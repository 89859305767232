<template>
  <b-form ref="clientForm" id="form-client" class="mb-4" @submit.prevent="onSubmit()">
    <!-- CLIENT NOTICE -->
    <div v-if="client.id">
      <b-alert show>
        <b-icon-info-circle></b-icon-info-circle> Client data for
        <b>{{ `${client.fname} ${client.lname}` }}</b>
      </b-alert>
    </div>

    <!-- GROUP NOTICE -->
    <div v-if="group.id">
      <b-alert show>
        <b-icon-info-circle></b-icon-info-circle> Adding a new client to a
        <a
          href="javascript:;"
          class="text-info"
          @click="showModalGroups({ name: `New User`, groups: [{ group }] })"
          ><b>{{ group.type.label }}</b> with {{ group.clients.length }} other
          client(s)</a
        >
      </b-alert>
    </div>

    <!-- FORM -->
    <b-form-row>
      <b-col cols="12" md="6">
        <b-form-group :label="$t('label.franchise')" class="section">
          <BaseFormFranchise
            v-if="!$store.getters.currentFranchiseCode"
            v-model="clientFranchise.franchiseCode"
            no-label
          />
          <p v-else class="pt-2 pl-2">{{ $store.getters.currentFranchiseCode }}</p>
        </b-form-group>

        <b-form-group :label="$t('label.source')">
          <BaseFormSelectClientSource
            v-model="clientFranchise.source"
            :franchiseCode="franchiseCode"
          ></BaseFormSelectClientSource>
        </b-form-group>

        <fieldset>
          <legend>Client</legend>
          <b-form-row>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.firstName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="client.fname"
                  id="clientFname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.lastName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="client.lname"
                  id="clientLname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
        <fieldset>
          <legend>Status</legend>
          <b-form-row align-v="center">
            <b-col cols="12" md="6">
              <b-form-group label="Status" label-cols-sm="3">
                <b-select
                  id="status"
                  :options="clientStatusOptions"
                  v-model="clientFranchise.status"
                  text-field="label"
                  value-field="@id"
                  required
                >
                  <template #first>
                    <b-select-option value="null">== Client Status ==</b-select-option>
                  </template>
                </b-select>
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
        <fieldset>
          <legend>School</legend>
          <b-form-row align-v="center">
            <b-col cols="12" md="6">
              <b-form-group label="School" label-cols-sm="3">
                <b-select
                  id="school"
                  :options="franchiseSchools"
                  v-model="clientProfile.school"
                  value-field="id"
                  text-field="data.name"
                  required
                >
                  <template #first>
                    <b-select-option value="null">== Client School ==</b-select-option>
                  </template>
                </b-select>
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
      </b-col>
      <b-col cols="12" md="6">
        <fieldset>
          <legend>Primary Contact</legend>
          <b-form-row>
            <b-col cols="12" lg="6">
              <b-form-group label="Relation" label-cols-sm="3" class="required">
                <b-form-select
                  id="pc-relationship"
                  v-model="contact.relationship"
                  :options="clientContactRelOpts"
                  required="required"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Relationship --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6"> </b-col>
          </b-form-row>
          <b-form-row v-if="contact.relationship !== $t('label.clientContactSelf')">
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.firstName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="contact.fname"
                  id="fname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.lastName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="contact.lname"
                  id="lname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" lg="6">
              <b-form-group :label="$t('label.email')" label-cols-sm="3">
                <b-form-input
                  v-if="!existing.email"
                  type="email"
                  v-model="contact.email"
                  maxlength="250"
                  id="email"
                  @blur="checkEmail"
                  :formatter="(val) => val.replace(/[^0-9a-z-\.\@]/i, '')"
                  trim
                ></b-form-input>
                <b-button-group v-else class="mt-1">
                  <b-button
                    size="sm"
                    variant="warning"
                    class="text-white"
                    :to="{ name: 'ClientForm', params: { id: existing.email } }"
                    >Edit Existing Client</b-button
                  >
                  <b-button
                    size="sm"
                    variant="success"
                    class="text-white"
                    @click="existing.email = null"
                    >Update Email</b-button
                  >
                </b-button-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group :label="$t('label.phone')" label-cols-sm="3">
                <BaseFormPhone
                  name="phone_primary"
                  id="phone-primary"
                  v-model="phones.mobile.number"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
        <fieldset>
          <legend>Mailing Address</legend>
          <b-form-row>
            <b-col cols="12" lg="7">
              <b-form-group :label="$t('label.country')" label-cols-sm="3">
                <BaseFormSelectCountry v-model="client.country" />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-group :label="$t('label.zip')" label-cols-sm="3" class="required">
                <BaseFormPostalCode
                  id="zip_code"
                  v-model="client.zipCode"
                  :required="true"
                  @pc-data="
                    (e) => {
                      if (e.city) {
                        client.city = e.city
                        client.state = e.state
                      }
                    }
                  "
                />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" lg="7">
              <b-form-group :label="$t('label.address')" label-cols-sm="3">
                <b-form-input
                  v-model="client.street1"
                  id="street1"
                  maxlength="150"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-group :label="$t('label.address2')" label-cols-sm="3">
                <b-form-input
                  class="street2"
                  v-model="client.street2"
                  id="street2"
                  size="10"
                  maxlength="35"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" lg="7">
              <b-form-group :label="$t('label.city')" label-cols-sm="3">
                <b-form-input
                  v-model="client.city"
                  id="city"
                  size="10"
                  maxlength="35"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-group :label="$t('label.state')" label-cols-sm="3">
                <BaseFormSelectState :country="client.country" v-model="client.state" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
      </b-col>
    </b-form-row>
    <BaseFormButtons size="md" @form-button-submit="onSubmit()" no-cancel no-reset />
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { constants } from '../../shared/constants'

const INI_STATUS = 'Pending'
const DEFAULT_CLIENT_FRANCHISE = {
  id: null,
  franchiseCode: null,
  lead: null,
  status: null,
}

export default {
  name: 'ClientForm',
  data() {
    return {
      group: {},
      client: {
        city: null,
        country: 'US',
        email: null,
        fname: null,
        id: null,
        lname: null,
        state: null,
        street1: null,
        street2: null,
        zipCode: null,
      },
      clientProfile: {
        school: null,
      },
      clientGroups: [],
      contact: {
        relationship: null,
        fname: null,
        id: null,
        lname: null,
        email: null,
      },
      // currently focussed franchise data
      clientFranchise: {
        school: null,
      },
      // all associated franchises
      clientFranchises: [],
      // editingForFranchise: null,
      clientPhones: [
        //  @TODO ? make phone an array so we can assign type
        // {
        //   id: null,
        //   type: '',
        //   number: null,
        //   primary: true
        // }
      ],
      phones: {
        mobile: {
          id: null,
          primary: true,
          number: null,
        },
      },
      existing: {
        email: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'franchise',
      'currentFranchise',
      'franchiseSchools',
      'clients',
      'clientStatusOptions',
      'clientContactRelOpts',
    ]),
    iniStatus() {
      const iniStatus = this.clientStatusOptions.find((e) => e.label === INI_STATUS)
      return iniStatus ? iniStatus['@id'] : null
    },
    franchiseCode() {
      return this.clientFranchise.franchiseCode || null
    },
  },
  watch: {
    franchiseCode(val, prev) {
      if (val && val !== prev) {
        this.setCurrentFranchiseData(val)
        this.getFranchiseSchools(val)
      } else {
        this.resetFranchiseSchools()
      }
    },
    '$route.query.cid'() {
      this.loadClientFromId(this.$route.query.cid)
    },
  },
  async mounted() {
    // set default data
    this.clientFranchise = DEFAULT_CLIENT_FRANCHISE
    // get status options
    await this.getClientStatusOptions()
    if (this.$route.query.gid) {
      // set group data
      await this.setGroupData(this.$route.query.gid)
    }
    if (this.$route.query.fc) {
      // set franchise code association
      this.clientFranchise.franchiseCode = this.$route.query.fc
    }
    if (this.$route.query.cid) {
      this.loadClientFromId(this.$route.query.cid)
    } else {
      if (this.currentFranchiseCode) {
        this.clientFranchise.franchiseCode = this.currentFranchiseCode
      }
      this.clientFranchise.status = this.iniStatus
    }
  },
  destroyed() {
    this.resetClient()
    this.resetFranchise()
  },
  methods: {
    ...mapActions([
      'getClient',
      'getClientContacts',
      'getContactRecords',
      'getClients',
      'getClientStatusOptions',
      'getGroup',
      'getGroupContacts',
      'postGroup',
      'postGroupClient',
      'getFranchiseClients',
      'getFranchiseSchools',
      'getFranchise',
      'resetFranchiseSchools',
      'postClient',
      'postFranchiseClient',
      'putFranchiseClient',
      'postGroupContact',
      'putGroupContact',
      'putClient',
      'resetClient',
      'resetFranchise',
      'showModalGroups',
      'getPhones',
      'postPhone',
      'putPhone',
    ]),
    async setGroupData(groupId) {
      // get group data
      const group = await this.getGroup(groupId)
      this.group = { ...this.$store.getters.group }
      this.clientFranchise.franchiseCode = this.group.franchise.franchiseCode
      // get contact data
      await this.getContactRecords({ groupId })
      const groupContacts = this.$store.getters.groupContacts
      if (groupContacts.length) {
        this.contact = { ...groupContacts[0].data }
      }
      return group
    },
    /**
     * checks for an existing entry with the entered email address
     */
    async checkEmail() {
      if (!this.client.email || this.client.id) return
      await this.getClients({
        email: this.client.email,
      })
      if (this.clients.length) {
        const e = 'A client with that email address already exists!'
        this.existing.email = this.clients[0].id
        this.client.email = null
        this.toastError(e)
      }
    },

    /**
     * loads client based on client id (cid)
     * @param { Int } cid
     */
    async loadClientFromId(cid) {
      this.group = {}
      let client = {}

      // load client data from franchise-clinets endpoint
      await this.getFranchiseClients({
        'client.id': cid,
      })
      this.clientFranchises = [...this.$store.getters.franchiseClients]

      if (this.clientFranchises.length) {
        this.setCurrentFranchiseData()
        client = this.clientFranchises[0].client
      } else {
        // load client data from clients endpoint
        await this.getClient(cid)
        client = { ...this.$store.getters.client }
      }

      this.clientGroups = [...client.groups]
      client.country = client.country || 'US'

      for (const k in this.client) {
        this.client[k] = client[k] || null
      }

      // set client profile data
      this.clientProfile = { ...client.clientProfile }
      this.clientProfile.school = this.clientProfile.school || null

      // load primary contact
      await this.loadPrimaryContact()

      const clientPhones = await this.getPhones({
        source: 'gt_client',
        pk: client.id,
        type: 'mobile',
        primary: true,
      })
      if (clientPhones.length) {
        this.phones.mobile.id = clientPhones[0].id
        this.phones.mobile.number = clientPhones[0].number
        this.phones.mobile.primary = clientPhones[0].primary
      }
    },

    async loadPrimaryContact() {
      // get primary contact
      let getContacts
      let clientContacts = null
      // from group
      const groupIndexHousehold = this.clientGroups.findIndex(
        (e) => e.group.type.id === 1
      ) // 1 = Household @TODO make const
      if (groupIndexHousehold !== -1) {
        getContacts = await this.getGroupContacts(
          this.clientGroups[groupIndexHousehold].group.id
        )
        clientContacts = this.$store.getters.groupContacts
      }
      // from client
      if (!clientContacts) {
        getContacts = await this.getContactRecords({ clientId: this.client.id })
        clientContacts = this.$store.getters.clientContacts
      }
      // assign
      if (clientContacts.length) {
        this.contact = { ...clientContacts[0].data }
      }
      return getContacts
    },

    /**
     * sets currently focussed franchise data
     * @param { String } franchiseCode of this.clientFranchises
     */
    setCurrentFranchiseData(franchiseCode) {
      let index = -1
      if (!franchiseCode && this.clientFranchises.length) {
        // use first found franchie
        index = 0
      } else if (this.clientFranchises.length) {
        // look for franchise via franchiseCode
        index = this.clientFranchises.findIndex((e) => e.franchiseCode === franchiseCode)
      }
      if (index !== -1) {
        this.clientFranchise = {
          id: this.clientFranchises[index].id,
          franchiseCode: this.clientFranchises[index].franchiseCode,
          lead: this.clientFranchises[index].lead,
          source: this.clientFranchises[index].source,
          status: this.clientFranchises[index].status
            ? this.clientFranchises[index].status['@id']
            : null,
        }
      } else {
        // use default object
        this.clientFranchise = DEFAULT_CLIENT_FRANCHISE
      }
    },
    /**
     * saves franchise / cleint association
     */
    saveFranchiseClient() {
      const index = this.clientFranchises.findIndex(
        (e) => e.franchiseCode === this.franchiseCode
      )
      if (index === -1) {
        const payload = {
          ...this.clientFranchise,
          ...{ client: this.$store.getters.client['@id'] },
        }
        delete payload.id
        this.postFranchiseClient(payload)
      } else {
        this.putFranchiseClient(this.clientFranchise)
      }
    },
    savePhones() {
      if (!this.phones.mobile.number) {
        return
      }
      // @todo itterate over array of phone numbers
      for (let k in this.phones) {
        if (!this.phones[k].id) {
          // add phone number
          this.postPhone({
            source: 'gt_client',
            pk: this.client.id,
            primary: true,
            number: this.phones[k].number,
            type: k,
          })
        } else {
          // update phone number
          this.putPhone({
            id: this.phones[k].id,
            primary: true,
            number: this.phones[k].number,
          })
        }
      }
    },
    /**
     * validates form submission
     */
    validate() {
      // @TODO
    },
    /**
     * submits client data
     */
    async onSubmit() {
      try {
        this.validate()
        const client = { ...this.client }
        client.clientProfile = { ...this.clientProfile }
        client.email = this.contact.email
        const methodIsPost = !client.id

        // save client
        if (methodIsPost) {
          // client.clientProfile = { ...this.clientProfile }
          await this.postClient(client)
          this.client.id = this.$store.getters.client.id
        } else {
          await this.putClient(client)
        }

        // franchise-client association
        this.saveFranchiseClient()

        // client phone(s)
        this.savePhones()

        // group association
        if (!this.clientGroups.length) {
          // create new group
          await this.postGroup({
            type: constants.API_IRI_GROUP_TYPES_HOUSEHOLD,
          })

          // link new group
          this.group = this.$store.getters.group

          // post client to group
          this.postGroupClient({
            group: this.group['@id'],
            client: this.$store.getters.client['@id'],
          })
        }

        if (this.contact.relationship !== this.$t('label.clientContactSelf')) {
          // client parent
          const user = { ...this.contact }
          if (methodIsPost) {
            user.franchise_code = this.franchiseCode
            user.group_pk = this.group.id
            // const clientContact = await this.postUser(user)
            // this.contact.id = clientContact.id
          } else {
            // await this.putUser(user)
          }

          // primary contact
          const primaryContact = { ...this.contact }
          if (methodIsPost) {
            primaryContact.franchise_code = this.franchiseCode
            primaryContact.group_pk = this.group.id
            const clientContact = await this.postGroupContact(primaryContact)
            this.contact.id = clientContact.id
          } else {
            await this.putGroupContact(primaryContact)
          }
        }

        this.toastSuccess(this.$t('client.clientSaved'))
        if (methodIsPost) {
          this.$router.push({ name: 'ClientForm', query: { cid: this.client.id } })
        }
      } catch (e) {
        // this.toastError(e)
        console.error('ClientForm::onSubmit()', e)
      }
    },
  },
}
</script>

<style lang="scss">
#form-client fieldset > legend {
  padding: 0.5rem !important;
}
#form-cient .section > legend {
  margin-bottom: 0.5rem !important;
}
</style>
